<template>
    <div class="main">
        <div class="box1">
          <div v-for="(itemd,index) in data" :key="index" class="flex wrap">
            <div class="col3" v-for="item in itemd" :key="item[0]">
              <ImagePreview :height="'unset'" :width="'90%'" class="hvr-push img" :src="require('../../assets/about/honor/'+item[0])" :srcList="[require('../../assets/about/honor/'+item[0])]">
                  <p class="img-text">{{item[1]}}</p>
              </ImagePreview>
            </div>
          </div>
        </div>
        <!-- <More @more = "more"></More> -->
    </div>
</template>
<script>
export default {
    data(){
        return {
            data:[
              [
                ['1-1.jpg','高新技术企业'],
                ['1-2.jpg','司法鉴定许可证'],
              ],
              [
                ['2-1.jpg','发明专利证书'],
                ['2-2.jpg','发明专利证书'],
                ['2-3.jpg','发明专利证书'],
              ],
              [
                ['3-1.jpg','教学科研实践基地'],
                ['3-2.jpg','智慧物证与物联网技术联合实验室'],
                ['3-3.jpg','物证技术联合研发中心'],
                ['3-4.jpg','司法鉴定战略合作单位'],
              ],
              [
                ['4-1.jpg','CMA资质认定证书'],
                ['4-2.jpg','CNAS能力验证结果通知'],
                ['4-3.jpg','CNAS能力验证结果通知'],
                ['4-4.jpg','CNAS能力验证结果通知'],
                ['4-5.jpg','CMA资质认定证书'],
              ],
            ]
        }
    },
    methods:{
        more(){
            console.log('more')
        }
    }
}
</script>
<style scoped>
.main{
    margin:0 auto;
    width:80vw;
    margin-bottom:2rem;
    max-width:1600px;
}
.img{
    /* width:33.3%; */
    max-width: 350px;
}
.img-text{
    margin: 2rem;
}
.box1{
  margin:2rem 0 ;
}
@media screen and (max-width:337px){
    .box1{
        justify-content:center;
    }
    .img{
        min-width: 200px;
    }
}
</style>